/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  DirectWithdrawAbi,
  DirectWithdrawAbiInterface,
} from "../DirectWithdrawAbi";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract ILendingPoolAddressesProvider",
        name: "_ap",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "mv",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "claimId",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "redeemed",
        type: "uint256",
      },
    ],
    name: "Redeem",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "mv",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "claimId",
        type: "uint32",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    inputs: [],
    name: "aaveV2LendingPool",
    outputs: [
      {
        internalType: "contract ILendingPool",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "getUserWithdrawsById",
    outputs: [
      {
        components: [
          {
            internalType: "uint64",
            name: "timestamp",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "lastVaultExpiry",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "indexTokenWithdrawn",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "srcVault",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "subVault",
            type: "address[]",
          },
          {
            internalType: "uint128[]",
            name: "withdrawn",
            type: "uint128[]",
          },
          {
            internalType: "uint128[]",
            name: "expected",
            type: "uint128[]",
          },
          {
            internalType: "uint64[]",
            name: "strikeTimestamp",
            type: "uint64[]",
          },
          {
            internalType: "uint64[]",
            name: "strikeX1e6",
            type: "uint64[]",
          },
        ],
        internalType: "struct DirectWithdraw.claimInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserWithdrawsLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "pendingVaultIds",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "pendingVaultLPs",
    outputs: [
      {
        internalType: "uint128",
        name: "",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "redeem",
    outputs: [
      {
        internalType: "uint256",
        name: "totalClaimed",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract CashVault_V1",
        name: "subVault",
        type: "address",
      },
    ],
    name: "redeemVault",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract AToken",
        name: "amv",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "mv_amt",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [
      {
        components: [
          {
            internalType: "uint64",
            name: "timestamp",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "lastVaultExpiry",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "indexTokenWithdrawn",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "srcVault",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "subVault",
            type: "address[]",
          },
          {
            internalType: "uint128[]",
            name: "withdrawn",
            type: "uint128[]",
          },
          {
            internalType: "uint128[]",
            name: "expected",
            type: "uint128[]",
          },
          {
            internalType: "uint64[]",
            name: "strikeTimestamp",
            type: "uint64[]",
          },
          {
            internalType: "uint64[]",
            name: "strikeX1e6",
            type: "uint64[]",
          },
        ],
        internalType: "struct DirectWithdraw.claimInfo",
        name: "newClaim",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class DirectWithdrawAbi__factory {
  static readonly abi = _abi;
  static createInterface(): DirectWithdrawAbiInterface {
    return new utils.Interface(_abi) as DirectWithdrawAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): DirectWithdrawAbi {
    return new Contract(address, _abi, signerOrProvider) as DirectWithdrawAbi;
  }
}
