export const Ust = () => (
  <svg height="100%" viewBox="6 6.5 20 20" width="100%">
    <path
      d="M16.65,18.25s-2.1,1.1-2,2.83,2.42,4.36,3.82,3.84,5.3-2.78,5.7-5-3.48-3.32-3.48-3.32Z"
      fill="#2844ad"
    />
    <path
      d="M22.07,8.82a8.82,8.82,0,0,0-9.28-1.16L10.52,10.9a2.29,2.29,0,0,0,1.54,1.54c1.3.43,6.93-1.74,6.93-1.74Z"
      fill="#2844ad"
    />
    <path
      d="M7.28,12s1.9-3.49,4.44-4.3,2.18,1.73,0,2.76S8.17,12.08,7.28,12Z"
      fill="#5493f7"
    />
    <path
      d="M6.92,13.08S4,21.27,12,25c0,0,.62-3.27-.51-5.34A26.15,26.15,0,0,0,8.47,15,19,19,0,0,1,6.92,13.08Z"
      fill="#5493f7"
    />
    <path
      d="M24.44,21S22.35,25,19.33,25a.58.58,0,0,1-.36-.78C19.16,23.64,21.05,21.58,24.44,21Z"
      fill="#5493f7"
    />
    <path
      d="M22.14,8.88a8.4,8.4,0,0,1,2.65,3.43,10.49,10.49,0,0,1,.38,7c-.51,1.35-1.3,1-3,.64S14,17.77,12.33,15.55s-.38-4,1.78-5.15S20.71,8,22.14,8.88Z"
      fill="#5493f7"
    />
    <path
      d="M13.15,22.69,13,19.11s0,0,0,0l4.84-.29a.06.06,0,0,1,0,0c0,.16,0,.32,0,.48s0,.34,0,.51,0,.35,0,.52,0,.34,0,.51,0,.35,0,.52,0,.35,0,.52,0,.32,0,.47,0,0,0,0,0,0,0,0h0l-4.7.28S13.18,22.71,13.15,22.69Z"
      fill="#3c3b6e"
    />
    <path
      d="M25.42,24.54c0,.16,0,.33,0,.49s0,0,0,0h0l-12.1.73s0,0,0,0,0-.32,0-.48h.08l12-.72S25.39,24.52,25.42,24.54Z"
      fill="#b12234"
    />
    <path
      d="M25.42,24.54h-.05l-12.08.73h0l0-.52h.08l12-.72a.12.12,0,0,1,.08,0Z"
      fill="#fefefe"
    />
    <path
      d="M25.36,23l0,.52H25.3l-12,.73a.12.12,0,0,1-.08,0l0-.52h.09l12-.73S25.34,23,25.36,23Z"
      fill="#fefefe"
    />
    <path
      d="M25.36,23h0l-12.1.73h0l0-.51a0,0,0,0,1,0,0h0l12-.73s.06,0,.09,0Z"
      fill="#b12234"
    />
    <path
      d="M25.35,22.47H25.3l-12.09.73h0l0-.52h0L18,22.4h.12l7.15-.43s.05,0,.08,0Z"
      fill="#fefefe"
    />
    <path d="M13.21,24.24h0l12.09-.73h0l0,.51h0l-12.09.73h0Z" fill="#b12234" />
    <path
      d="M17.92,19.3l0-.52,7.27-.44s0,0,0,0,0,.33,0,.49h-.09L18,19.3S17.94,19.32,17.92,19.3Z"
      fill="#b12234"
    />
    <path
      d="M17.92,19.3l.88-.06,6.37-.38h0l0,.51a0,0,0,0,1,0,0h0L18,19.82a.06.06,0,0,1-.08,0Z"
      fill="#fefefe"
    />
    <path
      d="M18,21.88l0-.52h.08l7.14-.44s.06,0,.08,0l0,.52,0,0h0l-7.16.43S18,21.9,18,21.88Z"
      fill="#fefefe"
    />
    <path
      d="M25.29,20.92h0L18,21.36h0l0-.52h.08l7.14-.43s.06,0,.08,0Z"
      fill="#b12234"
    />
    <path
      d="M25.27,20.4l-1.46.09L18,20.84l0-.51a.05.05,0,0,1,0,0h0l7.17-.43h.07Z"
      fill="#fefefe"
    />
    <path d="M18,21.88l7.25-.44h0l0,.51h0l-7.23.43h0Z" fill="#b12234" />
    <path
      d="M25.26,19.88h-.05L18,20.33l0-.52,2.24-.13,5-.31h0Z"
      fill="#b12234"
    />
    <path
      d="M16.66,21.56l0-.12s0,0,0-.05a.21.21,0,0,1-.08-.06h.11s0,0,0,0l0-.12s0,.06,0,.09,0,.05.07,0h.08l-.08.07s0,0,0,0a.76.76,0,0,0,0,.11l-.1-.06s0,0,0,0Z"
      fill="#fdfdfd"
    />
    <path
      d="M14.16,21.48h.09s0,0,.05,0a.22.22,0,0,1,0-.1s0,.07,0,.11,0,0,0,0h.12s0,.05-.08.06,0,0,0,.08a.46.46,0,0,1,0,.09l-.09-.06s0,0,0,0a.31.31,0,0,1-.09.07.45.45,0,0,0,0-.11s0,0,0-.05l-.09-.06Z"
      fill="#fdfdfd"
    />
    <path
      d="M15.63,20.53l-.09-.06s0,0,0,0l-.09.08a1,1,0,0,1,0-.12s0,0,0,0l-.09-.07h.11s0,0,0,0a.84.84,0,0,1,0-.12l0,.09s0,0,.06,0h.09l-.08.07a.06.06,0,0,0,0,.05A.36.36,0,0,1,15.63,20.53Z"
      fill="#fdfdfd"
    />
    <path
      d="M14.15,19.53l0-.11a0,0,0,0,0,0-.05l-.08-.06h.09s.05,0,.05-.05a.46.46,0,0,0,0-.09.49.49,0,0,1,0,.11s0,0,0,0h.1a.46.46,0,0,1-.07.07,0,0,0,0,0,0,.05.49.49,0,0,0,0,.11l-.1-.06a0,0,0,0,0,0,0Z"
      fill="#fdfdfd"
    />
    <path
      d="M16.87,19.15l-.07.05a.05.05,0,0,0,0,.08l0,.09-.1-.06h0l-.09.08,0-.12s0,0,0,0l-.09-.07h.1s0,0,.05,0a.36.36,0,0,1,0-.1s0,0,0,.06,0,.07.09.06h.07Z"
      fill="#fdfdfd"
    />
    <path
      d="M13.71,20.42h.11a0,0,0,0,0,0,0l0-.11a.49.49,0,0,1,0,.11s0,0,0,0h.11a.24.24,0,0,1-.08.06,0,0,0,0,0,0,.07l0,.1-.09-.06s0,0-.05,0l-.09.07a.29.29,0,0,1,0-.1s0-.05,0-.07l-.07,0Z"
      fill="#fdfdfd"
    />
    <path
      d="M16.5,20.26l-.09.07s0,0,0,0a.28.28,0,0,1,0,.11l-.09-.06s0,0-.05,0a.36.36,0,0,1-.09.08c0-.05,0-.09,0-.12s0,0,0,0a.27.27,0,0,1-.08-.07h.1s0,0,0,0a.52.52,0,0,1,0-.11l0,.08c0,.06,0,.06.07.05h.08Z"
      fill="#fdfdfd"
    />
    <path
      d="M17.57,21.13a.76.76,0,0,1,0,.11s0,0,0,0h.11l-.08.06s0,0,0,.06a.17.17,0,0,1,0,.1l-.09-.06h0a.22.22,0,0,1-.09.07l0-.1s0,0,0-.06a.21.21,0,0,1-.08-.06h.11s0,0,0,0Z"
      fill="#fdfdfe"
    />
    <path
      d="M15,21.43h.1s0,0,0,0a.45.45,0,0,1,0-.11l0,.1a0,0,0,0,0,.06,0h.09l-.08.07s0,0,0,.05a.81.81,0,0,1,0,.11l-.09-.06s0,0,0,0l-.09.08,0-.12a.06.06,0,0,0,0-.05l-.08,0Z"
      fill="#fdfdfe"
    />
    <path
      d="M13.44,19.21a1,1,0,0,0,0,.1s0,0,0,0h.1l-.07.06s0,0,0,.07a1,1,0,0,1,0,.1l-.1-.07s0,0,0,0l-.09.06,0-.1s0,0,0,0l-.1-.06h.11s0,0,.05,0A.54.54,0,0,1,13.44,19.21Z"
      fill="#fdfdfd"
    />
    <path
      d="M15.18,19.47l-.09-.06a0,0,0,0,0,0,0l-.09.08a1,1,0,0,1,0-.12s0,0,0,0l-.09-.07H15s0,0,0,0l0-.1a.49.49,0,0,1,0,.11s0,0,0,0h.11l-.09.07s0,0,0,.05A.29.29,0,0,1,15.18,19.47Z"
      fill="#fdfdfd"
    />
    <path
      d="M15.87,19.06a.76.76,0,0,1,0,.11s0,0,0,0h.11l-.09.08s0,0,0,0,0,.07,0,.11l-.08-.06s-.05,0-.07,0l-.07.06a.3.3,0,0,1,0-.1,0,0,0,0,0,0-.06l-.09-.06h.12a0,0,0,0,0,0,0Z"
      fill="#fdfdfd"
    />
    <path
      d="M17.61,19.32l-.09-.06a0,0,0,0,0,0,0l-.1.08a.7.7,0,0,0,0-.12s0,0,0,0a.27.27,0,0,1-.08-.07h.1s0,0,0,0a.26.26,0,0,1,0-.11.36.36,0,0,0,0,.11,0,0,0,0,0,0,0,.2.2,0,0,1,.11,0l-.08.07s0,0,0,0a.42.42,0,0,1,0,.1Z"
      fill="#fdfdfd"
    />
    <path
      d="M15.85,21.59l0-.1s0,0,0,0l-.1-.07h.11s0,0,0,0l0-.11s0,.08,0,.11,0,0,0,0h.12l-.09.08s0,0,0,0a.76.76,0,0,0,0,.11l-.1-.06s0,0,0,0l-.08.07Z"
      fill="#fdfdfd"
    />
    <path
      d="M13.7,21.51l-.09.07s0,0,0,0a.42.42,0,0,1,0,.12l-.08-.06s0,0-.07,0l-.08.06a.61.61,0,0,0,0-.11s0,0,0-.05l-.1-.06h.12a0,0,0,0,0,0,0,1,1,0,0,0,0-.12l0,.12s0,0,0,0h.11Z"
      fill="#fdfdfd"
    />
    <path
      d="M17,20.45a.29.29,0,0,1,0-.11s0,0,0-.06L17,20.22h.11s0,0,0,0l0-.12s0,.08,0,.11a0,0,0,0,0,0,0,.21.21,0,0,1,.11,0l-.08.07s0,0,0,0a.76.76,0,0,0,0,.11l-.09-.06s0,0-.06,0Z"
      fill="#fdfdfd"
    />
    <path
      d="M14.69,20.22l0,0c0,.09,0,.09.13.09h0l-.08.07s0,0,0,0a.36.36,0,0,1,0,.11l-.09-.06s0,0-.05,0l-.08.07a.36.36,0,0,1,0-.11s0,0,0,0l-.09-.07h.1s0,0,0,0Z"
      fill="#fdfdfd"
    />
    <path
      d="M15.16,22a.76.76,0,0,1,0,.11s0,0,0,0h.11l-.07.07s0,0,0,.07a.46.46,0,0,1,0,.09l-.09-.06a0,0,0,0,0,0,0,.36.36,0,0,1-.09.08c0-.05,0-.08,0-.12s0,0,0,0l-.1-.07h.09a.07.07,0,0,0,.07-.06A.37.37,0,0,1,15.16,22Z"
      fill="#fefefe"
    />
    <path
      d="M13.36,22.25h.12s0,0,0,0l0-.12a1,1,0,0,0,0,.1,0,0,0,0,0,0,0h.11l-.09.08s0,0,0,0a.76.76,0,0,0,0,.11l-.1-.06s0,0,0,0l-.09.07,0-.11s0,0,0,0Z"
      fill="#fdfdfe"
    />
    <path
      d="M15.88,22.32s0-.07,0-.1,0,0,0,0l-.1-.07h.12a0,0,0,0,0,0,0S16,22,16,22a.49.49,0,0,1,0,.11s0,0,0,0a.17.17,0,0,1,.1,0l-.09.07s0,0,0,0a.49.49,0,0,1,0,.11L16,22.25s0,0-.05,0l-.08.06Z"
      fill="#fdfdfe"
    />
    <path
      d="M17.6,21.85s0,.08,0,.12,0,0,0,0h.12l-.09.08s0,0,0,.05l0,.11-.1-.07h0l-.09.08,0-.12s0,0,0,0L17.42,22h.11s0,0,0,0A.7.7,0,0,1,17.6,21.85Z"
      fill="#fdfdfe"
    />
    <path
      d="M14.47,20a.36.36,0,0,0-.09.08s0,0,0,0a.49.49,0,0,1,0,.11l-.1-.06h0l-.1.08a.7.7,0,0,0,0-.12s0,0,0,0l-.1-.07a.41.41,0,0,1,.11,0s0,0,.05-.05a.29.29,0,0,1,0-.09.21.21,0,0,1,0,.1s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M16.84,20.1l-.1-.07s0,0,0,0l-.09.08,0-.12s0,0,0,0l-.1-.07h.08c.06,0,.06,0,.08-.07l0-.08,0,.12s0,0,0,0h.11l-.09.08s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M14,21.35,14,21.29s0,0-.06,0l-.08.07c0-.05,0-.08,0-.12s0,0,0,0l-.09-.07h.11s0,0,0,0a.27.27,0,0,1,0-.12s0,.08,0,.11a0,0,0,0,0,0,0h.12l-.1.08s0,0,0,0A1.17,1.17,0,0,1,14,21.35Z"
      fill="#fefefe"
    />
    <path
      d="M17.71,19.81l-.09.08a0,0,0,0,0,0,0,.45.45,0,0,1,0,.11L17.56,20a0,0,0,0,0,0,0l-.09.08,0-.12s0,0,0,0l-.1-.06h.11a0,0,0,0,0,0,0,.81.81,0,0,0,0-.11.84.84,0,0,1,0,.12s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M16.16,21h.12a0,0,0,0,0,0,0l0-.12a.76.76,0,0,1,0,.11s0,0,0,0h.11l-.09.08s0,0,0,0a.84.84,0,0,0,0,.12l-.09-.07s0,0-.05,0l-.09.07,0-.11s0,0,0-.05Z"
      fill="#fefefe"
    />
    <path
      d="M14.9,20H15s.05,0,.06-.05l0-.1s0,.08,0,.12,0,0,0,0h.12l-.09.08s0,0,0,0a.49.49,0,0,1,0,.11l-.09-.06h0a.36.36,0,0,1-.09.08c0-.05,0-.08,0-.12s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M14.17,22.2h.1s.05,0,.06-.05a.44.44,0,0,1,0-.1.76.76,0,0,1,0,.11s0,0,0,0h.11l-.09.08s0,0,0,0a.76.76,0,0,0,0,.11l-.1-.06s0,0,0,0l-.09.08,0-.11s0,0,0-.06Z"
      fill="#fdfdfe"
    />
    <path
      d="M16.91,22.27l-.09-.07h0l-.1.08s0-.08,0-.11,0,0,0-.05l-.1-.07h.12s0,0,0,0a.23.23,0,0,1,0-.11l0,.07c0,.06,0,.06.09.06H17l-.09.08s0,0,0,.05A.81.81,0,0,1,16.91,22.27Z"
      fill="#fdfdfe"
    />
    <path
      d="M14.54,21.09h.1s0,0,0-.05l0-.1s0,.08,0,.12,0,0,0,0h.12l-.09.08s0,0,0,0a.76.76,0,0,0,0,.11l-.1-.06h0a.71.71,0,0,1-.09.07l0-.11s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M15.35,21h.12s0,0,0,0,0-.07,0-.12l0,.11s0,0,0,0h.11l-.09.08a0,0,0,0,0,0,0l0,.1-.09-.05a0,0,0,0,0-.06,0l-.08.07c0-.05,0-.08,0-.12s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M17.28,21.16l-.09-.07s0,0-.05,0l-.09.07a.81.81,0,0,1,0-.11s0,0,0,0L17,21h.12a0,0,0,0,0,0,0s0-.07,0-.12l0,.09s0,.06.06.05h.09l-.08.07s0,0,0,.06Z"
      fill="#fefefe"
    />
    <path
      d="M16.09,19.91,16,20s0,0,0,0l0,.12-.09-.07a0,0,0,0,0-.06,0l-.08.07a.35.35,0,0,1,0-.11s0,0,0-.05l-.09-.06h.1s0,0,0,0l0-.11s0,.08,0,.11a0,0,0,0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M13.37,20.3a.43.43,0,0,1,0-.11s0,0,0,0l-.1-.07h.09c.05,0,.05,0,.07-.07a.41.41,0,0,1,0-.08l0,.11s0,0,0,0h.12l-.1.08s0,0,0,0a.36.36,0,0,1,0,.11l-.09-.06s0,0-.05,0A.27.27,0,0,1,13.37,20.3Z"
      fill="#fefefe"
    />
    <path
      d="M15.74,21.75s-.09.05-.1.09a.93.93,0,0,0,0,.13h0l-.09-.07s0,0-.05,0l-.09.07s0-.08,0-.12,0,0,0,0l-.09-.06h.11a0,0,0,0,0,0,0l0-.11a.42.42,0,0,1,0,.1s0,0,.05,0Z"
      fill="#fefefe"
    />
    <path
      d="M14.12,20.76h.11s0,0,0,0l0-.12a.84.84,0,0,1,0,.12s0,0,0,0h.12l-.09.08s0,0,0,0a.84.84,0,0,0,0,.12l-.09-.07s0,0-.05,0L14.2,21l0-.12s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M16.86,20.82l-.09-.06s0,0-.05,0l-.09.07,0-.1s0-.05,0-.06l-.09-.06h.12a0,0,0,0,0,0,0,.76.76,0,0,1,0-.11l0,.1s0,0,.05,0h.11l-.08.06s0,0,0,.07Z"
      fill="#fefefe"
    />
    <path
      d="M14.85,19.63l-.08.07s0,0,0,.05l0,.11-.1-.07s0,0,0,0l-.08.07,0-.12s0,0,0,0l-.09-.06h.11s0,0,0,0,0-.07,0-.12,0,.08,0,.12,0,0,0,0h.11Z"
      fill="#fefefe"
    />
    <path
      d="M17.28,19.49l-.07.06s0,0,0,.06a.3.3,0,0,1,0,.1l-.09-.06s0,0-.05,0l-.09.08a1,1,0,0,1,0-.12,0,0,0,0,0,0-.05l-.08-.06H17a0,0,0,0,0,0,0l0-.11a.49.49,0,0,1,0,.11s0,0,0,0h.1Z"
      fill="#fefefe"
    />
    <path
      d="M13.39,21c0-.05,0-.08,0-.12s0,0,0,0l-.09-.06h.11s0,0,0,0,0-.07,0-.12,0,.08,0,.11,0,0,0,0h.1s0,0-.05.06,0,0,0,.1l0,.08-.1-.07s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M15.82,20.88l0-.11s0,0,0-.05a.24.24,0,0,1-.09-.06h.1s0,0,0,0a.81.81,0,0,0,0-.11l0,.12s0,0,0,0h.12l-.1.08s0,0,0,0,0,.07,0,.12L16,20.8a0,0,0,0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M17,21.67h.09s0,0,0,0l0-.1s0,.07,0,.11,0,0,0,0h.1a.13.13,0,0,1-.07.06s0,0,0,.07a1,1,0,0,1,0,.1l-.08-.05s-.05,0-.08,0l-.07.06a.29.29,0,0,1,0-.1,0,0,0,0,0,0-.06L17,21.68Z"
      fill="#fefefe"
    />
    <path
      d="M16.55,21.7l-.08.07s0,0,0,.05a.76.76,0,0,0,0,.11l-.09-.06s0,0-.06,0l-.08.07,0-.12s0,0,0,0a.35.35,0,0,1-.09-.06h.11s0,0,0,0a.61.61,0,0,1,0-.11.38.38,0,0,1,0,.1,0,0,0,0,0,.06,0Z"
      fill="#fefefe"
    />
    <path
      d="M14.94,21.79l-.09.08s0,0,0,.05a.28.28,0,0,1,0,.11L14.79,22s0,0-.06,0l-.09.07,0-.11s0,0,0-.05l-.1-.06h.11s0,0,0,0a.81.81,0,0,0,0-.11.49.49,0,0,1,0,.11s0,0,.05,0Z"
      fill="#fefefe"
    />
    <path
      d="M13.93,21.71a.84.84,0,0,1,0,.12s0,0,0,0h.11l-.09.08s0,0,0,0a.83.83,0,0,1,0,.12L14,22s0,0,0,0a.36.36,0,0,1-.09.08c0-.05,0-.08,0-.12s0,0,0,0l-.1-.06h.12a0,0,0,0,0,0,0S13.92,21.76,13.93,21.71Z"
      fill="#fefefe"
    />
    <path
      d="M15.31,19.6h.11s0,0,0,0a.61.61,0,0,1,0-.11.49.49,0,0,1,0,.11s0,0,0,0h.11l-.09.08a0,0,0,0,0,0,.05.76.76,0,0,0,0,.11l-.1-.07h0l-.09.08,0-.11s0,0,0-.05l-.08-.05Z"
      fill="#fefefe"
    />
    <path
      d="M16.29,19.39a.84.84,0,0,1,0,.12s0,0,0,0h.11l-.08.07a0,0,0,0,0,0,0s0,.07,0,.11l-.09-.06s0,0,0,0l-.09.07a.29.29,0,0,1,0-.1s0-.05,0-.07l-.07-.05h.1s0,0,0,0S16.28,19.44,16.29,19.39Z"
      fill="#fefefe"
    />
    <path
      d="M14,19.91l-.08-.06a0,0,0,0,0-.06,0l-.09.07s0-.08,0-.11,0,0,0,0l-.09-.06h.11a0,0,0,0,0,0,0,.84.84,0,0,1,0-.12l0,.11a0,0,0,0,0,0,0h.11l-.08.07s0,0,0,.07A.62.62,0,0,1,14,19.91Z"
      fill="#fefefe"
    />
    <path
      d="M14.94,20.71h.11s0,0,0,0l0-.12s0,.05,0,.08a.06.06,0,0,0,.08.05s0,0,.07,0l-.08.07s0,0,0,0a.66.66,0,0,1,0,.11l-.1-.07s0,0,0,0l-.09.08,0-.12s0,0,0,0Z"
      fill="#fefefe"
    />
    <path
      d="M17.73,20.55l-.08.06s0,0,0,.05l0,.11-.09-.06s0,0,0,0l-.08.07a.35.35,0,0,1,0-.11.06.06,0,0,0,0-.05.24.24,0,0,1-.09-.06h.11s0,0,0,0a.26.26,0,0,1,0-.11l0,.1s0,0,.05,0h.1Z"
      fill="#fefefe"
    />
  </svg>
);
