export const sizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const screens = {
  xs: `@media (max-width: ${sizes.xs}px)`,
  sm: `@media (max-width: ${sizes.sm}px)`,
  md: `@media (max-width: ${sizes.md}px)`,
  lg: `@media (max-width: ${sizes.lg}px)`,
  xl: `@media (max-width: ${sizes.xl}px)`,
  xxl: `@media (max-width: ${sizes.xxl}px)`,
};
