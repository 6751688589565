import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  padding: 4px;
  border-radius: 8px;
  background-color: #323844;
`;
