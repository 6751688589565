export const ArrowBack = () => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 17 17"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1327 7.55614H3.07549L9.27079 1.36092C9.53372 1.10327 9.53795 0.681241 9.28031 0.418305C9.02258 0.15537 8.60055 0.151057 8.33761 0.408784C8.33444 0.411876 8.33127 0.41505 8.32809 0.418305L0.99487 7.75153C0.734458 8.01161 0.734051 8.43332 0.994138 8.69373C0.994301 8.69398 0.994463 8.69414 0.99487 8.69414L8.32809 16.0274C8.59103 16.285 9.01306 16.2808 9.2707 16.0178C9.52477 15.7586 9.52477 15.3439 9.2707 15.0847L3.07549 8.88945H16.1327C16.5008 8.88945 16.7993 8.59095 16.7993 8.22279C16.7993 7.85463 16.5008 7.55614 16.1327 7.55614Z"
      fill="#5D5D5D"
    />
  </svg>
);
