export const Info = () => (
  <svg height="100%" viewBox="0 0 16 16" width="100%">
    <path
      d="M8 0C3.58667 0 0 3.58659 0 8C0 12.4134 3.58659 16 8 16C12.4134 16 16 12.4134 16 8C16 3.58659 12.4134 0 8
      0ZM8 14.6667C4.31992 14.6667 1.33333 11.6801 1.33333 8C1.33333 4.31992 4.31992 1.33333 8 1.33333C11.6801 1.33333
      14.6667 4.31992 14.6667 8C14.6667 11.6801 11.6801 14.6667 8 14.6667Z"
      fill="#061F3A"
    />
    <path
      d="M7.9987 6.46484C7.62533 6.46484 7.33203 6.75815 7.33203 7.13151V11.6648C7.33203 12.0382 7.62533 12.3315
      7.9987 12.3315C8.37206 12.3315 8.66536 12.0382 8.66536 11.6648V7.13151C8.66536 6.77154 8.37206 6.46484 7.9987
      6.46484Z"
      fill="#061F3A"
    />
    <path
      d="M8.01211 3.73186C7.66538 3.70522 7.34544 4.06519 7.33203 4.42516V4.53185C7.33203 4.89182 7.61209 5.15848
      7.98529 5.17189H7.99868C8.35865 5.17189 8.65194 4.85195 8.66535 4.50523V4.3585C8.66535 3.97191 8.38529 3.73187
      8.01209 3.73187L8.01211 3.73186Z"
      fill="#061F3A"
    />
  </svg>
);
