export const NewWindow = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8684 0.000351814C15.8455 0.00103834 15.8227 0.00309804 15.8001 0.00639357H10.9592C10.7945 0.00405937 10.6358 0.0677698 10.5185 0.183383C10.4013 0.298997 10.3352 0.456896 10.3352 0.621534C10.3352 0.786171 10.4013 0.94393 10.5185 1.05954C10.6358 1.17516 10.7945 1.239 10.9592 1.23667H14.3955L6.83309 8.79859V8.79845C6.71418 8.91311 6.64621 9.07073 6.64456 9.2359C6.64305 9.40108 6.708 9.55995 6.82485 9.67665C6.94157 9.7935 7.10044 9.85845 7.26563 9.8568C7.43082 9.85529 7.58846 9.78732 7.70297 9.66842L15.2654 2.10649V5.54259C15.263 5.70722 15.3269 5.86595 15.4425 5.9832C15.5581 6.10046 15.7159 6.16651 15.8805 6.16651C16.0452 6.16651 16.2031 6.10046 16.3187 5.9832C16.4343 5.86595 16.4981 5.70722 16.4957 5.54259V0.69956C16.5206 0.520237 16.4651 0.339268 16.3443 0.204431C16.2234 0.0697344 16.0495 -0.00496025 15.8686 0.000256075L15.8684 0.000351814Z"
      fill="white"
    />
    <path
      d="M7.26616 2.4668C5.73096 2.4668 4.57074 2.4646 3.64644 2.56896C2.72214 2.67331 1.98203 2.89314 1.45274 3.42315C0.923423 3.95315 0.705811 4.69174 0.602154 5.61581C0.498353 6.53974 0.500002 7.69985 0.500002 9.23316C0.500002 10.767 0.498766 11.9274 0.604491 12.8519C0.710352 13.7764 0.932924 14.5154 1.46348 15.0446C1.99404 15.5738 2.7327 15.7933 3.65609 15.8975C4.57947 16.0019 5.73706 15.9997 7.26632 15.9997C8.79576 15.9997 9.9531 16.0004 10.8766 15.8952C11.8 15.79 12.5401 15.5694 13.0703 15.0398C13.6005 14.5102 13.821 13.7711 13.9269 12.8471C14.0327 11.9232 14.0326 10.7644 14.0326 9.23328C14.0349 9.06851 13.9711 8.90993 13.8555 8.79281C13.7399 8.67555 13.5821 8.6095 13.4175 8.6095C13.2529 8.6095 13.095 8.67555 12.9793 8.79281C12.8637 8.90993 12.8 9.06866 12.8024 9.23342C12.8024 10.7634 12.797 11.9046 12.705 12.708C12.613 13.5114 12.4436 13.9273 12.2004 14.1702C11.9573 14.4131 11.5415 14.5821 10.7383 14.6735C9.93503 14.7651 8.7946 14.7697 7.26629 14.7697C5.73769 14.7697 4.59765 14.7655 3.7943 14.6748C2.99095 14.584 2.57492 14.4158 2.33214 14.1737C2.08936 13.9317 1.91955 13.5155 1.82756 12.7115C1.73557 11.9076 1.73022 10.766 1.73022 9.23343C1.73022 7.7004 1.73365 6.5584 1.82386 5.75408C1.9142 4.94973 2.08268 4.53439 2.32365 4.29315C2.56462 4.0519 2.98011 3.88287 3.78455 3.79211C4.58902 3.70135 5.73167 3.69723 7.26631 3.69723C7.43093 3.69957 7.58966 3.63572 7.70691 3.52011C7.82417 3.40449 7.89021 3.24673 7.89021 3.08209C7.89021 2.91733 7.82417 2.75956 7.70691 2.64394C7.58965 2.52833 7.43078 2.46447 7.26616 2.4668Z"
      fill="white"
    />
  </svg>
);
