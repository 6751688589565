export const ViceVersa = () => (
  <svg
    fill="none"
    height="16px"
    viewBox="0 0 16 16"
    width="16px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.77333 7.42857L4 9.71429L5.77333 12V10.2857H8.88889V9.14286H5.77333V7.42857ZM12 6.28571L10.2267 4V5.71429H7.11111V6.85714H10.2267V8.57143L12 6.28571Z"
      fill="white"
    />
    <rect height="15" rx="7.5" stroke="white" width="15" x="0.5" y="0.5" />
  </svg>
);
