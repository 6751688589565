/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  BasicVaultReaderAbi,
  BasicVaultReaderAbiInterface,
} from "../BasicVaultReaderAbi";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "OWNER",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "VAULT_READER",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "depositQueue",
    outputs: [
      {
        internalType: "contract DepositQueue",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract OptionsContractV1",
        name: "vault",
        type: "address",
      },
    ],
    name: "getVaultInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "max_deposit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "total_deposit_current",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "total_deposit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "total_exit_current",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "total_exit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "total_asset",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "premium",
            type: "uint256",
          },
          {
            internalType: "uint256[4]",
            name: "strike_price",
            type: "uint256[4]",
          },
          {
            internalType: "uint256",
            name: "time_on_expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "epoch",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "assetScaleX1e36",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "period",
            type: "uint256",
          },
        ],
        internalType: "struct BaseReader_V1.Vault",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract OptionsContractV1",
        name: "vault",
        type: "address",
      },
    ],
    name: "myVaultPosition",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_depositQueue",
        type: "address",
      },
    ],
    name: "setDepositQueue",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    name: "setOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_vault",
        type: "address",
      },
      {
        internalType: "address",
        name: "_reader",
        type: "address",
      },
    ],
    name: "setReader",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class BasicVaultReaderAbi__factory {
  static readonly abi = _abi;
  static createInterface(): BasicVaultReaderAbiInterface {
    return new utils.Interface(_abi) as BasicVaultReaderAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): BasicVaultReaderAbi {
    return new Contract(address, _abi, signerOrProvider) as BasicVaultReaderAbi;
  }
}
