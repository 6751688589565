export const NewTab = () => (
  <svg height="100%" viewBox="0 0 17 17" width="100%">
    <path
      d="M17 0V6.95461H15.9698V1.75889L7.06136 10.6674L6.33298 9.93902L15.2414 1.0305H10.0457V0.000275825L17
      0ZM13.9091 15.7123C13.9091 15.8519 13.7911 15.9699 13.6515 15.9699L1.28788 15.9697C1.14827 15.9697 1.03023
      15.8517 1.03023 15.7121V3.34834C1.03023 3.20873 1.14827 3.09068 1.28788 3.09068H9.53029V2.06046H1.28788C0.577797
      2.06046 0 2.63827 0 3.34836V15.7121C0 16.4222 0.577797 17 1.28788 17H13.6515C14.3616 17 14.9394 16.4222 14.9394
      15.7121L14.9392 7.46961H13.909L13.9091 15.7123Z"
      fill="#259DDF"
    />
  </svg>
);
