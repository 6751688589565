export const Fil = () => (
  <svg viewBox="141.764 202.698 40 40.001">
    <path
      clipRule="evenodd"
      d="M20,40C9,40,0,31,0,19.9C0.1,8.9,9-0.1,20.1,0C31.1,0.1,40,9,40,20.2C39.9,31.1,31,40,20,40"
      fill="rgb(0, 144, 255)"
      fillRule="evenodd"
      transform="matrix(1, 0, 0, 1, 141.763901, 202.698959)"
    />
    <path
      clipRule="evenodd"
      d="M21.9,17.6l-0.6,3.2l5.7,0.8l-0.4,1.5L21,22.3c-0.4,1.3-0.6,2.7-1.1,3.9c-0.5,1.4-1,2.8-1.6,4.1 c-0.8,1.7-2.2,2.9-4.1,3.2c-1.1,0.2-2.3,0.1-3.2-0.6c-0.3-0.2-0.6-0.6-0.6-0.9c0-0.4,0.2-0.9,0.5-1.1c0.2-0.1,0.7,0,1,0.1 c0.3,0.3,0.6,0.7,0.8,1.1c0.6,0.8,1.4,0.9,2.2,0.3c0.9-0.8,1.4-1.9,1.7-3c0.6-2.4,1.2-4.7,1.7-7.1v-0.4L13,21.1l0.2-1.5l5.5,0.8 l0.7-3.1l-5.7-0.9l0.2-1.6l5.9,0.8c0.2-0.6,0.3-1.1,0.5-1.6c0.5-1.8,1-3.6,2.2-5.2s2.6-2.6,4.7-2.5c0.9,0,1.8,0.3,2.4,1 c0.1,0.1,0.3,0.3,0.3,0.5c0,0.4,0,0.9-0.3,1.2c-0.4,0.3-0.9,0.2-1.3-0.2c-0.3-0.3-0.5-0.6-0.8-0.9C26.9,7.1,26,7,25.3,7.7 c-0.5,0.5-1,1.2-1.3,1.9c-0.7,2.1-1.2,4.3-1.9,6.5l5.5,0.8l-0.4,1.5L21.9,17.6"
      fill="rgb(255, 255, 255)"
      fillRule="evenodd"
      transform="matrix(1, 0, 0, 1, 141.763901, 202.698959)"
    />
  </svg>
);
