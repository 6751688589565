export const Arrow = () => (
  <svg fill="none" height="100%" viewBox="0 0 12 7" width="100%">
    <path
      clipRule="evenodd"
      d="M1.5255 0.26265C1.17652 -0.0863299 0.610714 -0.0863299 0.261735 0.26265C-0.087244 0.611629 -0.087244 1.17743
       0.261735 1.52641L5.36812 6.6328C5.7171 6.98178 6.2829 6.98178 6.63188 6.6328L11.7383 1.52641C12.0872 1.17743
       12.0872 0.611629 11.7383 0.26265C11.3893 -0.0863299 10.8235 -0.0863299 10.4745 0.26265L5.99756 4.73959L1.5255
        0.26265Z"
      fill="#9C9C9C"
      fillRule="evenodd"
    />
  </svg>
);
