export const Twitter = () => (
  <svg height="100%" viewBox="0 0 18 15" width="100%">
    <path
      d="M16.1572 3.64114C16.1682 3.79983 16.1682 3.95851 16.1682 4.11866C16.1682 8.99836 12.4534 14.6262 5.6607
      14.6262V14.6232C3.65411 14.6262 1.68921 14.0514 0 12.9677C0.291773 13.0028 0.585009 13.0203 0.878976
      13.021C2.54186 13.0225 4.15722 12.4645 5.46545 11.4371C3.88519 11.4071 2.49945 10.3768 2.01536 8.87259C2.56892
      8.97935 3.13931 8.95741 3.68263 8.80897C1.95978 8.46089 0.720293 6.94718 0.720293 5.18922C0.720293 5.17314
      0.720293 5.15778 0.720293 5.14242C1.23364 5.42835 1.80841 5.58703 2.39634 5.60458C0.773675 4.52012 0.273492
      2.36144 1.25338 0.673684C3.12834 2.98081 5.8947 4.38337 8.86435 4.53182C8.56673 3.24919 8.97331 1.90513 9.93272
      1.00348C11.4201 -0.394689 13.7594 -0.323026 15.1576 1.16363C15.9846 1.00056 16.7773 0.697084 17.5027
      0.267102C17.2271 1.12195 16.6501 1.84809 15.8793 2.30952C16.6113 2.22323 17.3265 2.02725 18 1.72816C17.5042
      2.47112 16.8797 3.11829 16.1572 3.64114Z"
    />
  </svg>
);
