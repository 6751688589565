import styled from "styled-components";

import {
  Container as ThetaIndexLayoutContainer,
  Title as ThetaIndexLayoutTitle,
} from "../../theta-index/components/ThetaIndexLayout.styles";

export const Container = styled(ThetaIndexLayoutContainer)``;

export const Title = styled(ThetaIndexLayoutTitle)`
  color: #eb5353;
`;
