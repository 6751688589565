export const Frax = () => (
  <svg height="100%" viewBox="0 0 570.4 570.4" width="100%">
    <g transform="translate(295.65791,-75.71863)">
      <circle
        clipRule="evenodd"
        cx="-10.5"
        cy="360.9"
        fillRule="evenodd"
        r="284.6"
        stroke="#000000"
        strokeMiterlimit={11.3386}
        strokeWidth={1.2373}
      />
      <path
        d="M-167.5,202.6c-12,12.6-21.9,23.2-22,23.4c-0.1,0.3,10.7,10.9,24,23.5
        c13.3,12.7,24.9,23.8,25.7,24.5l1.5,1.4l-2.7,4.5c-23.7,39.2-28.6,86.4-13.7,129.8c2.8,8.2,7.6,18.7,11.7,25.6c2,3.4,2.9,5.6,2.7,6
        c-0.2,0.4-12.3,13.2-26.9,28.5c-14.6,15.2-26.5,27.9-26.7,28.1c-0.1,0.3,40.8,39.8,44.7,43l1.5,1.3l23.1-24.1
        c12.7-13.3,24.6-25.8,26.4-27.7l3.4-3.4l3.2,2c16.3,10.4,35.4,17.6,56.5,21.4c10.6,1.9,30.8,2.4,42.1,1
        c21.8-2.5,43.4-9.8,61.1-20.5l4.6-2.8l4.9,4.8c10.7,10.4,50.7,48.4,51.1,48.6c0.4,0.2,44.3-45.7,44.2-46.3
        c-0.1-0.3-30.2-29.2-48.6-46.5l-6.1-5.8l2.8-4.6c23.5-39.1,28.4-86.5,13.2-130.2c-2.2-6.4-8.9-20.8-12.8-27.4l-1.9-3.3l25.7-26.9
        c14.1-14.7,25.6-27,25.5-27.3c-0.4-1-45.4-43.5-46-43.5c-0.4,0-11.7,11.5-25.2,25.7c-13.5,14.1-24.7,25.7-25,25.8
        c-0.2,0.1-1.5-0.5-2.7-1.4c-3.4-2.3-12-7-16.8-9.2c-14.6-6.7-28.2-10.8-44-13.1c-8.2-1.2-32.8-1.4-40.4-0.3
        c-18.2,2.6-33.1,7.1-48.3,14.7c-4.4,2.1-9.5,4.9-11.5,6.1l-3.6,2.2l-4.1-4c-2.2-2.2-12.2-11.6-22.1-21.1c-9.9-9.4-20-19-22.4-21.4
        l-4.4-4.3L-167.5,202.6z M0.4,267.8c15.9,1.9,30.1,7.3,43.2,16.5c6.7,4.7,16.7,14.7,21.4,21.4c26.7,37.8,21.6,89.2-12,120.7
        c-28.7,26.8-70.2,32.8-105.1,15c-25.2-12.9-43-36.5-48.7-64.8c-1.8-9-1.8-25.8,0-34.7c1.7-8.8,4-15.1,8.3-23.9
        C-75.3,283.4-38,263.2,0.4,267.8L0.4,267.8z"
        fill="#FFFFFF"
        id="path862"
      />
    </g>
  </svg>
);
