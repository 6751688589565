export const TriangleUp = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0434 10.0875L9.09833 3.9635C8.69808 3.46783 7.94255 3.46783 7.54229 3.9635L2.59724 10.0875C2.41262 10.3125 2.32031 10.65 2.32031 10.875C2.32031 11.1 2.41262 11.4375 2.59724 11.6625C2.78185 11.8875 2.96647 12 3.24339 12H13.3972C13.6742 12 13.8588 11.8875 14.0434 11.6625C14.228 11.4375 14.3203 11.1 14.3203 10.875C14.3203 10.65 14.228 10.3125 14.0434 10.0875Z"
      stroke="white"
    />
  </svg>
);
