/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  LongVaultPositionManagerAbi,
  LongVaultPositionManagerAbiInterface,
} from "../LongVaultPositionManagerAbi";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "lendingPool",
        type: "address",
      },
    ],
    name: "CancelQueue",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "lendingPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "collateralRemoved",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountRepayed",
        type: "uint256",
      },
    ],
    name: "ClosePosition",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "lendingPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountBorrowed",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "collateralReceived",
        type: "uint256",
      },
    ],
    name: "OpenPosition",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "asset",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "lendingPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "QueuePosition",
    type: "event",
  },
  {
    inputs: [],
    name: "ADDRESSES_PROVIDER",
    outputs: [
      {
        internalType: "contract ILendingPoolAddressesProvider",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "LENDING_POOL",
    outputs: [
      {
        internalType: "contract ILendingPool",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
    ],
    name: "cancelQueue",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "cancelQueueByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "amtToBorrow",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
    ],
    name: "closeVault",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maxCollateralToUse",
        type: "uint256",
      },
    ],
    name: "closeVaultAndWithdrawPosition",
    outputs: [
      {
        internalType: "uint256",
        name: "amtWithdrawable",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
    ],
    name: "closeVaultAndWithdrawPosition",
    outputs: [
      {
        internalType: "uint256",
        name: "amtWithdrawable",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maxCollateralToUse",
        type: "uint256",
      },
    ],
    name: "closeVaultBySwap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "collat",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amtToDeposit",
        type: "uint256",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amtToBorrow",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minSwapCollateralToReceive",
        type: "uint256",
      },
    ],
    name: "depositAndOpenBySwapOptionPosition",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "collat",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amtToDeposit",
        type: "uint256",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amtToBorrow",
        type: "uint256",
      },
    ],
    name: "depositAndQueueOptionPosition",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "assets",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "premiums",
        type: "uint256[]",
      },
      {
        internalType: "address",
        name: "initiator",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "params",
        type: "bytes",
      },
    ],
    name: "executeOperation",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
      {
        internalType: "address",
        name: "_sender",
        type: "address",
      },
    ],
    name: "findQueueIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract CashVaultV1",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "longQueue",
    outputs: [
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amtToBorrow",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "address",
        name: "collateral",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "collateralDeposited",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minQueueValue",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "onSettle",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "startRange",
        type: "uint256",
      },
    ],
    name: "processQueue",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "queueFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "asset",
        type: "address",
      },
    ],
    name: "removeDust",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_minQueueValue",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_queueFee",
        type: "uint256",
      },
    ],
    name: "setQueueParams",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "uniswapV3Router",
    outputs: [
      {
        internalType: "contract ISwapRouter",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract CashVaultV1",
        name: "vault",
        type: "address",
      },
      {
        internalType: "contract ILendingPool",
        name: "LP",
        type: "address",
      },
    ],
    name: "withdrawMaxCollateral",
    outputs: [
      {
        internalType: "uint256",
        name: "amtWithdrawable",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class LongVaultPositionManagerAbi__factory {
  static readonly abi = _abi;
  static createInterface(): LongVaultPositionManagerAbiInterface {
    return new utils.Interface(_abi) as LongVaultPositionManagerAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LongVaultPositionManagerAbi {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as LongVaultPositionManagerAbi;
  }
}
