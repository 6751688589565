export enum ModalContentType {
  swap = 1,
  swapInProgress = 2,
  withdraw = 3,
  withdrawSummary = 4,
  withdrawClaim = 5,

  openLongOptionPosition = 6,
  closeLongOptionPosition = 7,
}
