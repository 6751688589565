export const Plus = () => (
  <svg fill="none" height="12" width="12">
    <rect
      fill="#ffffff"
      height="1.251"
      rx=".2"
      width="11.8"
      x=".01"
      y="5.004"
    />
    <rect
      fill="#ffffff"
      height="1.25"
      rx=".2"
      transform="rotate(90 6.53 0)"
      width="11.81"
      x="6.53"
    />
  </svg>
);
