import { useState, useCallback } from 'react';
import { useDidMount } from './useDidMount';
import { useMutationObserver } from './useMutationObserver';
/**
 * @param element HTML element whose boundingclientrect is needed
 * @returns ClientRect
 */
function getBoundingClientRect(element) {
    return element.getBoundingClientRect();
}
/**
 * useBoundingclientRect hook
 *
 * @param ref The React ref whose ClientRect is needed
 * @returns ClientRect
 */
function useBoundingclientrect(ref) {
    var _a = useState(null), value = _a[0], setValue = _a[1];
    var update = useCallback(function () {
        setValue(ref.current ? getBoundingClientRect(ref.current) : null);
    }, []);
    useDidMount(function () {
        update();
    });
    useMutationObserver(ref, update);
    return value;
}
export { useBoundingclientrect };
