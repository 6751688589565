export const NavigateBack = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 12H5" stroke="white" />
    <path d="M12 19L5 12L12 5" stroke="white" />
  </svg>
);
