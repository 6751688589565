export const TriangleDown = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0434 4.9125L9.09833 11.0365C8.69808 11.5322 7.94255 11.5322 7.54229 11.0365L2.59724 4.9125C2.41262 4.6875 2.32031 4.35 2.32031 4.125C2.32031 3.9 2.41262 3.5625 2.59724 3.3375C2.78185 3.1125 2.96647 3 3.24339 3H13.3972C13.6742 3 13.8588 3.1125 14.0434 3.3375C14.228 3.5625 14.3203 3.9 14.3203 4.125C14.3203 4.35 14.228 4.6875 14.0434 4.9125Z"
      stroke="white"
    />
  </svg>
);
