import { InputCard } from "../../basic-vault-modal/components";

import { Container, Title } from "./CollateralInput.styles";

export const CollateralInput = () => (
  <Container>
    <Title>Collateral</Title>
    <InputCard />
  </Container>
);
