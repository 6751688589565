export const Chain = () => (
  <svg
    fill="none"
    height="13"
    viewBox="0 0 12 13"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.57731 11.9879C3.53255 13.0327 1.82819 13.0327 0.783576 11.9879C-0.261192 10.9431 -0.261192 9.23892 0.783576 8.19419L3.1547 5.82308C4.15069 4.82709 5.74313 4.77212 6.79664 5.67852C6.93359 5.79571 6.99768 5.97699 6.96482 6.1543C6.93184 6.33149 6.80682 6.47769 6.63689 6.53769C6.46695 6.5978 6.27784 6.5626 6.14089 6.44553C5.47786 5.87502 4.4984 5.90217 3.86612 6.53441L1.49499 8.90553C0.831965 9.56855 0.831965 10.6138 1.49499 11.2766C2.1579 11.9395 3.20312 11.9395 3.86612 11.2766L6.11853 9.024C6.21244 8.92775 6.34109 8.87313 6.4756 8.8722C6.6101 8.87138 6.73933 8.92447 6.8344 9.01956C6.92949 9.11464 6.98258 9.24388 6.98176 9.37836C6.98083 9.51286 6.92621 9.64139 6.82996 9.73531L4.57731 11.9879ZM8.84531 7.71994C7.84931 8.71593 6.25688 8.7709 5.20337 7.86449H5.20348C5.10115 7.77771 5.03752 7.65374 5.02677 7.51994C5.01612 7.38614 5.05904 7.25364 5.14629 7.15165C5.23343 7.04966 5.35775 6.98662 5.49154 6.97645C5.62532 6.96627 5.75761 7.0099 5.85923 7.0975C6.52226 7.66789 7.50173 7.64085 8.13401 7.00861L10.5051 4.6375C11.1682 3.97447 11.1682 2.92938 10.5051 2.26638C9.84223 1.60348 8.79701 1.60348 8.13401 2.26638L5.88148 4.5189C5.78768 4.61551 5.65903 4.67036 5.52441 4.67129C5.38968 4.67223 5.26033 4.61925 5.16513 4.52405C5.06993 4.42885 5.01683 4.29938 5.01776 4.16476C5.0187 4.03015 5.07367 3.9015 5.17015 3.80758L7.42268 1.55506C8.46745 0.510294 10.1718 0.510294 11.2164 1.55506C12.2612 2.59982 12.2612 4.30417 11.2164 5.34878L8.84531 7.71994Z"
      fill="#ffffff"
    />
  </svg>
);
