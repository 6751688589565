/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  DirectDepositorAbi,
  DirectDepositorAbiInterface,
} from "../DirectDepositorAbi";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract ILendingPoolAddressesProvider",
        name: "_ap",
        type: "address",
      },
      {
        internalType: "contract WETH9",
        name: "_wnative",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "mv",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amtIn",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amtOut",
        type: "uint256",
      },
    ],
    name: "Deposit",
    type: "event",
  },
  {
    inputs: [],
    name: "WNATIVE",
    outputs: [
      {
        internalType: "contract WETH9",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "aaveV2LendingPool",
    outputs: [
      {
        internalType: "contract ILendingPool",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IndexVault_V1",
        name: "mv",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "deposit",
    outputs: [
      {
        internalType: "uint256",
        name: "maxMeta",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IndexVault_V1",
        name: "mv",
        type: "address",
      },
    ],
    name: "depositNative",
    outputs: [
      {
        internalType: "uint256",
        name: "maxMeta",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IndexVault_V1",
        name: "mv",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256",
      },
    ],
    name: "estimateInput",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IndexVault_V1",
        name: "mv",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "estimateOutput",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class DirectDepositorAbi__factory {
  static readonly abi = _abi;
  static createInterface(): DirectDepositorAbiInterface {
    return new utils.Interface(_abi) as DirectDepositorAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): DirectDepositorAbi {
    return new Contract(address, _abi, signerOrProvider) as DirectDepositorAbi;
  }
}
