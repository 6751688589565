export const Flip = () => (
  <svg fill="none" height="100%" viewBox="0 0 18 18" width="100%">
    <path
      d="M0.662546 11.9559C0.369658 12.2202 0.348227 12.6703 0.612541 12.9632L4.56297 17.3387C4.56654 17.3422 4.57011
      17.3458 4.57368 17.3494C4.59511 17.3708 4.61655 17.3922 4.64155 17.4101C4.65584 17.4208 4.67012 17.4351 4.68441
      17.4458C4.70941 17.4637 4.73799 17.478 4.76656 17.4958C4.78085 17.503 4.79156 17.5101 4.80585 17.5173C4.84157
      17.5315 4.87729 17.5423 4.91658 17.553C4.92729 17.5565 4.93444 17.5601 4.94515 17.5601C4.99159 17.5708 5.04159
      17.5744 5.0916 17.5744C5.1416 17.5744 5.19161 17.5673 5.23804 17.5601C5.24876 17.5565 5.2559 17.553 5.26662
      17.553C5.30233 17.5423 5.34162 17.5315 5.37734 17.5173C5.39163 17.5101 5.40235 17.503 5.41663 17.4958C5.44521
      17.4815 5.47378 17.4672 5.49878 17.4458C5.51307 17.4351 5.52736 17.4244 5.54165 17.4101C5.56665 17.3922 5.58808
      17.3708 5.60951 17.3494C5.61308 17.3458 5.61665 17.3422 5.62023 17.3387L9.57065 12.9632C9.83497 12.6703 9.81354
      12.2202 9.52065 11.9559C9.22776 11.6916 8.77414 11.7131 8.5134 12.0059L5.80953 15.0027V1.14405C5.80953 0.751151
      5.48807 0.429688 5.09517 0.429688C4.70227 0.429688 4.38081 0.751151 4.38081 1.14405V15.0027L1.67694
      12.0059C1.40906 11.7131 0.955435 11.6916 0.662546 11.9559ZM12.9067 17.5744C13.2996 17.5744 13.6211 17.2529
      13.6211 16.86V3.00139L16.325 5.99815C16.4643 6.15531 16.6607 6.23389 16.8572 6.23389C17.0286 6.23389 17.2001
      6.17317 17.3358 6.04815C17.6287 5.78384 17.6501 5.33379 17.3858 5.0409L13.4389 0.665427C13.4354 0.661855 13.4318
      0.658284 13.4282 0.654712C13.3996 0.622566 13.3675 0.597563 13.3318 0.568988C13.3282 0.568988 13.3282 0.565416
      13.3246 0.565416C13.2068 0.479693 13.0639 0.429688 12.9067 0.429688C12.7496 0.429688 12.6067 0.479693 12.4888
      0.565416C12.4853 0.565416 12.4853 0.568988 12.4817 0.568988C12.446 0.593991 12.4138 0.622566 12.3852
      0.654712C12.3817 0.658284 12.3781 0.661855 12.3745 0.665427L8.42767 5.0409C8.16336 5.33379 8.18479 5.78384
      8.47768 6.04815C8.77057 6.31247 9.22062 6.28746 9.48493 5.99815L12.1888 3.00139V16.86C12.1924 17.2529 12.5138
      17.5744 12.9067 17.5744Z"
      fill="white"
    />
  </svg>
);
