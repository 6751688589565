import { VaultModalType } from "../../root/types";

export const productTitlesMap = {
  [VaultModalType.index]: "Stronghold",
  [VaultModalType.basic]: "Basic",
  [VaultModalType.degen]: "Degen",
  [VaultModalType.wheel]: "Wheel",
  [VaultModalType.long]: "Long",
  [VaultModalType.longTrade]: "Long",
  [VaultModalType.longPosition]: "Long",
};
