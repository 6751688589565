import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 8px 0;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
`;
