import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  overflow-x: hidden;
  padding: 32px 0 16px 0;
  margin: -32px 0 -16px 0;
`;
