export const Discord = () => (
  <svg height="100%" viewBox="0 0 18 15" width="100%">
    <path
      d="M15.2378 1.51231C14.0905 0.985899 12.8602 0.598062 11.5739 0.375932C11.5505 0.371645 11.5271 0.382358
      11.515 0.403786C11.3568 0.685197 11.1815 1.05232 11.0588 1.34088C9.6753 1.13375 8.29889 1.13375 6.94374
      1.34088C6.82099 1.04591 6.63936 0.685197 6.48043 0.403786C6.46836 0.383073 6.44496 0.37236 6.42154
      0.375932C5.13593 0.597352 3.90567 0.985189 2.7577 1.51231C2.74776 1.51659 2.73924 1.52374 2.73359
      1.53302C0.400044 5.01929 -0.239213 8.41987 0.0743851 11.7783C0.075804 11.7947 0.0850274 11.8104 0.0977985
      11.8204C1.63741 12.9511 3.12878 13.6375 4.59246 14.0925C4.61588 14.0996 4.6407 14.091 4.65561 14.0717C5.00184
      13.5989 5.31048 13.1004 5.57511 12.5761C5.59072 12.5454 5.57582 12.509 5.5439 12.4968C5.05435 12.3111 4.5882
      12.0847 4.1398 11.8276C4.10433 11.8069 4.10149 11.7561 4.13412 11.7318C4.22848 11.6611 4.32286 11.5876 4.41297
      11.5133C4.42927 11.4997 4.45198 11.4969 4.47115 11.5054C7.41696 12.8504 10.6062 12.8504 13.5172 11.5054C13.5364
      11.4962 13.5591 11.499 13.5761 11.5126C13.6662 11.5869 13.7606 11.6611 13.8557 11.7318C13.8883 11.7561 13.8862
      11.8069 13.8507 11.8276C13.4023 12.0897 12.9361 12.3111 12.4459 12.4961C12.414 12.5083 12.3998 12.5454 12.4154
      12.5761C12.6857 13.0996 12.9943 13.5982 13.3342 14.071C13.3484 14.091 13.3739 14.0996 13.3973 14.0925C14.8681
      13.6375 16.3595 12.9511 17.8991 11.8204C17.9126 11.8104 17.9211 11.7954 17.9225 11.779C18.2978 7.8963 17.2939
      4.52361 15.2612 1.53373C15.2562 1.52374 15.2477 1.51659 15.2378 1.51231ZM6.01502 9.73337C5.12812 9.73337 4.39735
      8.91913 4.39735 7.91917C4.39735 6.91921 5.11395 6.10497 6.01502 6.10497C6.92315 6.10497 7.64686 6.92636 7.63266
      7.91917C7.63266 8.91913 6.91606 9.73337 6.01502 9.73337ZM11.9961 9.73337C11.1092 9.73337 10.3784 8.91913 10.3784
      7.91917C10.3784 6.91921 11.095 6.10497 11.9961 6.10497C12.9042 6.10497 13.6279 6.92636 13.6137 7.91917C13.6137
      8.91913 12.9042 9.73337 11.9961 9.73337Z"
    />
  </svg>
);
