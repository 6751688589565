export const Warning = () => (
  <svg fill="none" height="100%" viewBox="0 0 12 11" width="100%">
    <path
      d="M11.9193 9.35517L6.78027 0.450128C6.61919 0.171554 6.32182 0 6.00004 0C5.67826 0 5.3809 0.171554 5.21981
      0.450128L0.080799 9.35517C0.0100041 9.47739 -0.0153919 9.62066 0.00906593 9.75974C0.0335627 9.89887 0.106351
      10.0248 0.214616 10.1155C0.322917 10.2062 0.459704 10.2558 0.600942 10.2555H11.3991C11.5403 10.2558 11.6771
      10.2062 11.7854 10.1155C11.8936 10.0248 11.9664 9.89887 11.9909 9.75974C12.0154 9.62066 11.99 9.47739 11.9192
      9.35517H11.9193ZM6.00012 8.50497C5.86747 8.50497 5.74027 8.45226 5.64646 8.35846C5.55265 8.26469 5.49998 8.13748
      5.49998 8.00484C5.49998 7.87219 5.55265 7.74499 5.64646 7.65118C5.74027 7.55737 5.86748 7.50467 6.00012
      7.50467C6.13275 7.50467 6.25997 7.55738 6.35378 7.65118C6.44755 7.74499 6.50026 7.8722 6.50026 8.00484C6.49987
      8.13737 6.44704 8.26434 6.35335 8.35803C6.25962 8.45176 6.13264 8.50458 6.00012 8.50497ZM6.50026 6.25433C6.50026
      6.43299 6.40493 6.5981 6.25017 6.68746C6.09545 6.77677 5.90479 6.77677 5.75003 6.68746C5.59531 6.59811 5.49998
      6.433 5.49998 6.25433V3.50349C5.49998 3.32482 5.59531 3.15971 5.75003 3.07035C5.90479 2.981 6.09545 2.981
      6.25017 3.07035C6.40493 3.15971 6.50026 3.32481 6.50026 3.50349V6.25433Z"
      fill="#EB5853"
    />
  </svg>
);
