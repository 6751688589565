import {
  InfoContainer,
  InfoTitle,
  InfoValue,
} from "../../long-option-modal/components/OrderInfo.styles";

export const SideInfo = () => (
  <InfoContainer>
    <InfoTitle>Side</InfoTitle>
    <InfoValue>Long</InfoValue>
  </InfoContainer>
);
