export const Search = () => (
  <svg height="100%" viewBox="0 0 30 28" width="100%">
    <path
      d="M13.3869 24.4911C20.2288 24.4911 25.7754 19.0086 25.7754 12.2455C25.7754 5.48243 20.2289 0 13.3869 0C6.54487
      0 0.998379 5.48249 0.998379 12.2456C0.998379 19.0086 6.54493 24.4911 13.3869 24.4911ZM13.3869 3.49875C18.274
      3.49875 22.2358 7.41481 22.2358 12.2456C22.2358 17.0763 18.274 20.9924 13.3869 20.9924C8.49974 20.9924 4.53797
      17.0763 4.53797 12.2456C4.53797 7.41481 8.4998 3.49875 13.3869 3.49875Z"
      fill="white"
    />
    <path
      d="M27.5461 27.9889C28.0166 27.9916 28.4687 27.8091 28.8027 27.4816C29.4967 26.8012 29.5012 25.6936 28.8129
      25.0076C28.8095 25.0043 28.8061 25.0009 28.8027 24.9975L22.1483 18.4199C21.4299 17.7339 20.2849 17.7535 19.5909
      18.4636C18.8969 19.1737 18.9167 20.3055 19.6351 20.9915L26.2896 27.4816C26.6236 27.8091 27.0757 27.9916 27.5461
      27.9889Z"
      fill="white"
    />
  </svg>
);
