export const Busd = () => (
  <svg height="100%" viewBox="0 0 336.41 337.42" width="100%">
    <path
      d="M168.2.71l41.5,42.5L105.2,147.71l-41.5-41.5Z"
      fill="#f0b90b"
      stroke="#f0b90b"
    />
    <path
      d="M231.2,63.71l41.5,42.5L105.2,273.71l-41.5-41.5Z"
      fill="#f0b90b"
      stroke="#f0b90b"
    />
    <path
      d="M42.2,126.71l41.5,42.5-41.5,41.5L.7,169.21Z"
      fill="#f0b90b"
      stroke="#f0b90b"
    />
    <path
      d="M294.2,126.71l41.5,42.5L168.2,336.71l-41.5-41.5Z"
      fill="#f0b90b"
      stroke="#f0b90b"
    />
  </svg>
);
