export const Sol = () => (
  <svg height="100%" viewBox="0 0 500 500" width="100%" xmlSpace="preserve">
    <path
      d="M250,0c138,0,250,111.9,250,250S388,500,250,500S0,388,0,250S111.9,0,250,0L250,0z"
      id="SVGID_1_"
    />
    <linearGradient
      gradientTransform="matrix(0.5 0 0 0.5 0 250)"
      gradientUnits="userSpaceOnUse"
      id="SVGID_2_"
      x1="242.5198"
      x2="755.6802"
      y1="267.3304"
      y2="-245.8303"
    >
      <stop offset="0" style={{ stopColor: "#CB4EE8" }} />
      <stop offset="1" style={{ stopColor: "#10F4B1" }} />
    </linearGradient>
    <path
      clipRule="evenodd"
      d="M115.6,226.1H347c2.9,0,5.6,1.1,7.6,3.2l36.6,36.8c6.8,6.8,2,18.4-7.6,18.4H152.2c-2.9,0-5.6-1.1-7.6-3.2
 L108,244.5C101.2,237.8,106,226.1,115.6,226.1z M107.9,177.3l36.6-36.8c2.1-2.1,4.8-3.2,7.6-3.2h231.3c9.6,0,14.5,11.6,7.6,18.4
 l-36.5,36.8c-2,2.1-4.8,3.2-7.6,3.2H115.6C106,195.7,101.2,184.1,107.9,177.3z M391.1,333.5l-36.6,36.9c-2,2-4.8,3.2-7.6,3.2H115.6
 c-9.6,0-14.4-11.6-7.7-18.4l36.6-36.9c2.1-2,4.8-3.2,7.6-3.2h231.3C393.1,315,398,326.6,391.1,333.5z"
      fill="url(#SVGID_2_)"
      fillRule="evenodd"
    />
  </svg>
);
